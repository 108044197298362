import React from 'react'
import { Link } from 'gatsby'



const HomeThinSection = (props) => (
	<section id="homethinsection">
		<div className="inner">
			<div className="">
				<div className="">
					<p className="h2 montserrat-thick text-center" style={{marginBottom: `1em`}}>Ready to take your business to the next level?</p>
				</div>
				<div className="text-center">
					<a href="https://calendly.com/cnelson-84/15min/" target="_blank" className="text-center button green-btn">SCHEDULE CONSULTATION</a>
				</div>
			</div>
		</div>
	</section>
)

export default HomeThinSection